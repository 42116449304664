@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Space+Grotesk:wght@400;500;600;700&display=swap');

:root {
  --font-primary: 'Inter', sans-serif;
  --font-secondary: 'Space Grotesk', sans-serif;
}

body {
  font-family: var(--font-primary);
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: -0.02em;
}

/* Text size utility classes */
.display-text {
  font-family: var(--font-secondary);
  font-size: clamp(2.5rem, 5vw, 4.5rem);
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: -0.03em;
}

.heading-xl {
  font-size: clamp(2rem, 4vw, 3.5rem);
  letter-spacing: -0.02em;
}

.heading-lg {
  font-size: clamp(1.75rem, 3vw, 2.5rem);
}

.heading-md {
  font-size: clamp(1.5rem, 2.5vw, 2rem);
}

.heading-sm {
  font-size: clamp(1.25rem, 2vw, 1.5rem);
}

.body-lg {
  font-size: 1.125rem;
}

.body-md {
  font-size: 1rem;
}

.body-sm {
  font-size: 0.875rem;
}

.caption {
  font-size: 0.75rem;
  letter-spacing: 0.02em;
}

/* Font weight utility classes */
.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}