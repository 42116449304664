@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Import the fonts */
@import './fonts.css';

/* Base styles */
body {
  font-family: var(--font-primary);
  overflow-x: hidden;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

/* Modern Glass Morphism Effects */
.glass {
  background: var(--card-bg);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid var(--card-border);
  box-shadow: var(--shadow);
}

.glass-dark {
  background: var(--card-bg);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid var(--card-border);
  box-shadow: var(--shadow);
}

.glass-card {
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.glass-card:hover {
  transform: translateY(-8px);
  box-shadow: var(--shadow-lg);
}

/* Light theme colors - More refined palette */
.theme-light {
  --bg-primary: #ffffff;
  --bg-secondary: #f8fafc;
  --text-primary: #0f172a;
  --text-secondary: #475569;
  --accent-primary: #3b82f6;
  --accent-secondary: #8b5cf6;
  --accent-tertiary: #ec4899;
  --border-color: rgba(226, 232, 240, 0.8);
  --card-bg: rgba(255, 255, 255, 0.95);
  --card-border: rgba(255, 255, 255, 0.2);
  --shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 30px rgba(0, 0, 0, 0.12);
  --shadow-highlight: 0 0 15px rgba(59, 130, 246, 0.4);
}

/* Dark theme colors - Deeper, more vibrant */
.theme-dark {
  --bg-primary: #0f172a;
  --bg-secondary: #1e293b;
  --text-primary: #f1f5f9;
  --text-secondary: #94a3b8;
  --accent-primary: #60a5fa;
  --accent-secondary: #a78bfa;
  --accent-tertiary: #f472b6;
  --border-color: rgba(51, 65, 85, 0.8);
  --card-bg: rgba(15, 23, 42, 0.95);
  --card-border: rgba(255, 255, 255, 0.1);
  --shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  --shadow-lg: 0 10px 30px rgba(0, 0, 0, 0.35);
  --shadow-highlight: 0 0 20px rgba(96, 165, 250, 0.5);
}

/* Enhanced Gradients */
.gradient-bg {
  background: linear-gradient(135deg, var(--accent-primary) 0%, var(--accent-secondary) 100%);
}

.gradient-text {
  background: linear-gradient(to right, var(--accent-primary), var(--accent-secondary), var(--accent-tertiary));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}

.gradient-border {
  position: relative;
  border-radius: 0.5rem;
}

.gradient-border::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, var(--accent-primary), var(--accent-secondary), var(--accent-tertiary));
  border-radius: 0.6rem;
  z-index: -1;
  animation: border-animation 8s linear infinite;
}

@keyframes border-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Improved smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Enhanced Animation classes */
.animated-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.animated-blob {
  position: absolute;
  border-radius: 50%;
  filter: blur(80px);
  opacity: 0.6;
  animation: blobAnimation 20s infinite alternate;
}

@keyframes blobAnimation {
  0% {
    transform: translate(0, 0) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.2);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.8);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

/* Modern custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--bg-secondary);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: var(--accent-primary);
  border-radius: 4px;
  transition: all 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--accent-secondary);
}

/* Smooth transitions */
* {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Enhanced hover effects */
.hover-lift {
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275), box-shadow 0.4s ease;
}

.hover-lift:hover {
  transform: translateY(-8px);
  box-shadow: var(--shadow-lg);
}

/* Added hover glow effect */
.hover-glow {
  transition: all 0.4s ease;
}

.hover-glow:hover {
  box-shadow: var(--shadow-highlight);
}

/* Modern button styles */
.btn-primary {
  background: linear-gradient(to right, var(--accent-primary), var(--accent-secondary));
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  box-shadow: 0 4px 14px rgba(59, 130, 246, 0.4);
  transition: all 0.3s ease;
}

.btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(59, 130, 246, 0.6);
}

.btn-secondary {
  background: transparent;
  color: var(--accent-primary);
  border: 2px solid var(--accent-primary);
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-secondary:hover {
  background-color: rgba(59, 130, 246, 0.1);
  transform: translateY(-3px);
}

/* Add these new utility classes */
.container-padding {
  padding-left: max(2rem, calc((100% - 1280px) / 2));
  padding-right: max(2rem, calc((100% - 1280px) / 2));
}

.section-spacing {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

@media (min-width: 768px) {
  .section-spacing {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

/* Modern card styles */
.card-modern {
  border-radius: 1rem;
  overflow: hidden;
  background: var(--card-bg);
  box-shadow: var(--shadow);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card-modern:hover {
  transform: translateY(-8px);
  box-shadow: var(--shadow-lg);
}

/* Text highlight effect */
.highlight-text {
  position: relative;
  display: inline-block;
}

.highlight-text::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-color: var(--accent-primary);
  opacity: 0.2;
  z-index: -1;
  border-radius: 2px;
}

/* Flex utility helpers */
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Blur overlay for modals/dialogs */
.blur-overlay {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background-color: rgba(15, 23, 42, 0.7);
}

/* Style for skill bars */
.skill-bar {
  height: 8px;
  border-radius: 4px;
  background: var(--bg-secondary);
  overflow: hidden;
}

.skill-progress {
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(to right, var(--accent-primary), var(--accent-secondary));
}